'use client';

import { useRouter as useNextRouter } from 'next/navigation';
import { useTransition } from 'react';
import { usePageLoading } from './PageLoadingProvider';


export const useRouterWithLoading = () => {
  const router = useNextRouter();
  const { setLoading } = usePageLoading();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_isPending, startTransition] = useTransition();

  const push = (href: string) => {
    setLoading(true);
    startTransition(() => {
      router.push(href);
      setLoading(false);
    });
  };

  const replace = (href: string) => {
    setLoading(true);
    startTransition(() => {
      router.replace(href);
      setLoading(false);
    });
  };

  return {
    ...router,
    push,
    replace,
  };
};
