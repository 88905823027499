'use client';
import dynamic from "next/dynamic";
import { CROSS_DOMAIN_GTM_ID, GTM_ID } from "src/data/constants";
import { PagePreloader } from "src/modules/global-loading";
import { RenderAfterInteract } from "src/modules/interact-watcher";
import { MessengersApp } from "src/modules/messengers";
import AppCookiesWatcher from "../AppCookiesWatcher/AppCookiesWatcher";
import AuthSynchronizerWatcher from "../AuthSynchronizerWatcher/AuthSynchronizerWatcher";
import GtmNoScript from "../GtmNoScript/GtmNoScript";
import GtmScript from "../GtmScript/GtmScript";

const ModalsList = dynamic(
  () => import('src/modules/modals/ModalsList'),
  { ssr: false },
);

const GlobalComponents = ({ host }: { host: string }) => {
  return (
    <>
      <ModalsList />
      <PagePreloader />
      <MessengersApp />
      <AuthSynchronizerWatcher />

      <RenderAfterInteract>
        <GtmScript gtmId={GTM_ID} />
        <GtmScript gtmId={CROSS_DOMAIN_GTM_ID} />
        <GtmNoScript gtmId={GTM_ID} />
        <GtmNoScript gtmId={CROSS_DOMAIN_GTM_ID} />
      </RenderAfterInteract>
      <AppCookiesWatcher host={host} />
    </>
  );
};
export default GlobalComponents;
