'use client';
import { ReactNode } from "react";
import { Category } from "src/api/types";
import GraphqlProvider from "src/graphql/GraphqlProvider";
import { AddressManagementRetailsProvider } from "src/modules/address-management";
import { PageLoadingProvider } from "src/modules/global-loading";
import { InteractWatcherProvider } from "src/modules/interact-watcher";
import { MessengersContextProvider } from "src/modules/messengers";
import { ServicesProvider } from "src/services";
import { TranslationDictionary } from "src/services/localize/useLocalizeService";
import { ThemeProvider } from "src/ui-kit/theme";
import { SWRConfig } from "swr";

interface GlobalProvidersProps {
  children: ReactNode;
  language: string;
  translations: TranslationDictionary;
  categories: Category[];
}

const GlobalProviders = (props: GlobalProvidersProps) => {
  const {
    children,
    language,
    translations,
    categories,
  } = props;

  return (
    <ThemeProvider skin='zakaz'>
      <InteractWatcherProvider >
        <GraphqlProvider>
          <SWRConfig value={{ errorRetryCount: 1, dedupingInterval: 600000 }}>
            <ServicesProvider
              initLang={language}
              translations={translations}
              initCategories={categories}
            >
              <PageLoadingProvider>
                <MessengersContextProvider>
                  <AddressManagementRetailsProvider language={language}>
                    {children}
                  </AddressManagementRetailsProvider>
                </MessengersContextProvider>
              </PageLoadingProvider>
            </ServicesProvider>
          </SWRConfig>
        </GraphqlProvider>
      </InteractWatcherProvider>
    </ThemeProvider>
  );
};
export default GlobalProviders;
