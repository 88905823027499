import { ApolloProvider } from '@apollo/client';
import client from './apollo-client';
import { ReactNode } from 'react';

const GraphqlProvider = ({ children }: { children: ReactNode }) => (
  <ApolloProvider client={client}>
    {children}
  </ApolloProvider>
);

export default GraphqlProvider;
