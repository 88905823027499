import { ApolloClient, InMemoryCache } from '@apollo/client';
import { apiRoot } from 'src/data/constants';

const client = new ApolloClient({
  uri: `${apiRoot}/graphql`,
  cache: new InMemoryCache(),
  credentials: 'include',
});

export default client;
