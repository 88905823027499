'use client';

import Link, { LinkProps } from 'next/link';
import { ReactNode, MouseEvent, AnchorHTMLAttributes } from 'react';
import { useRouterWithLoading } from './useRouterWithLoading';

type CustomLinkProps = LinkProps &
Omit<AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> & {
  children: ReactNode;
};

const LinkWithLoading = ({ children, onClick, ...props }: CustomLinkProps) => {
  const router = useRouterWithLoading();

  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (onClick) {
      onClick(e);
    }

    if (
      e.defaultPrevented ||
      e.metaKey ||
      e.ctrlKey ||
      e.shiftKey ||
      e.altKey ||
      (props.target && props.target !== '_self')
    ) {
      return;
    }

    e.preventDefault();
    router.push(props.href.toString());
  };

  return (
    <Link {...props} onClick={handleClick}>
      {children}
    </Link>
  );
};

export default LinkWithLoading;
